/* This example requires Tailwind CSS v2.0+ */
const navigation = {
    main: [
        { name: 'Home', href: '/' },
        { name: 'Companies', href: '/portfolio' },
        { name: 'Services', href: '/services' },
        { name: 'Team', href: '/about' },
        { name: 'Contact', href: '/contact' },
    ],
}

export default function Footer() {
    return (
        <footer className="bg-gray-800 h-screen" aria-labelledby="footer-heading">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <a href={item.href} className="text-base text-gray-500 hover:text-indigo-200">
                                {item.name}
                            </a>
                        </div>
                    ))}
                </nav>
                <p className="mt-8 text-center text-base text-gray-400">&copy; Since 2020 NBGK Software UG, Berlin. All rights reserved.</p>
            </div>
        </footer>
    )
}
